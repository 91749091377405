
import { defineComponent, ref, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
//import { Vue } from "vue-class-component";
import http from "@/modules/httpclient";
import { CognitoUserPool, CognitoUser, AuthenticationDetails} from 'amazon-cognito-identity-js'

export default defineComponent({
  setup() {
    const route = useRoute()
    const router = useRouter()

    const form = ref()
    const login = reactive({
      email:"",
      password:""
    })

    const changePasswordForm = ref()
    const changePasswordVisible = ref(false)
    const changePassword = reactive({
      password:"",
      password2:""
    })

    const companyID = ref('')
    const userPoolID = ref('')
    const clientID = ref('')
    const domain = ref('')
    const idp = ref('')
    const region = ref('')

    let cognitoUser:CognitoUser

    const loginProviders = (process.env.VUE_APP_LOGIN_PROVIDER ?? "").toUpperCase().split(',')
    const loginEmailEnable = loginProviders.includes('EMAIL')
    const loginGoogleEnable = loginProviders.includes('GOOGLE')

    // 入力チェック
    const rules = {
      email:[
        {
          required:true,
          message:"入力必須です",
          trigger:'blur'
        },
        {
          type:'email',
          message:"メールアドレスを入力してください",
          trigger:'blur'
        }
      ],
      password:[
        {
          required:true,
          message:"入力必須です",
          trigger:'blur'
        }
      ]
    }

    const changePasswordRules = {
      password:[
        {
          required:true,
          message:"入力必須です",
          trigger:'blur'
        },
        {
          validator(rule, value, callback){
            // チェックはcognitoのポリシーに合わせる必要がある
            const errMsg = "パスワードは8文字以上で英字小文字、英字大文字、数字、記号を含むものにしてください"
            if(value.length < 8){
              callback(new Error(errMsg))
            }
            
            if(!value.match(/[a-z]/)){
              callback(new Error(errMsg))
            }
            if(!value.match(/[A-Z]/)){
              callback(new Error(errMsg))
            }
            if(!value.match(/[0-9]/)){
              callback(new Error(errMsg))
            }
            if(!value.match(/[\^$*.\[\]{}()?\-"!@#%&\/\\,><':;|_~`+=]/)){
              callback(new Error(errMsg))
            }
            callback()
          },
          trigger:'blur'
        }
      ],
      password2:[
        {
          required:true,
          message:"入力必須です",
          trigger:'blur'
        },
        {
          validator(rule, value, callback){
            if(changePassword.password !== value){
              callback(new Error("パスワードと確認用パスワードが一致しません"))
            }
            callback()
          },
          trigger:'blur'
        }
      ]
    }


    const setCognito = async (provider)=>{
        const apiUrl = process.env.VUE_APP_API_URL
        try {
          const res = await fetch(`${apiUrl}/internal-api/get-cognito/${provider}`)
          const data = await res.json()
          if ( data.resultCode != 0){
            throw new Error('response error')
          }
          userPoolID.value = data.cognitoSetting.userPoolID
          clientID.value = data.cognitoSetting.clientID
          domain.value = data.cognitoSetting.domain
          idp.value = data.cognitoSetting.identityProvider
          region.value = data.cognitoSetting.region

          sessionStorage.setItem('spf.auth.domain', domain.value)
          sessionStorage.setItem('spf.auth.region', region.value)
          sessionStorage.setItem('spf.auth.clientID', clientID.value)
          sessionStorage.setItem('spf.auth.pid', userPoolID.value)
          return true
        }
        catch (reason) {
          router.push({ name: 'error', params: { message: 'ログイン処理でエラーが発生しました' } })
          return false
        }
        
    }

    const loginByGoogle = async () => {
      
      if(!await setCognito('Google')){
        return
      }

      const location = `https://${domain.value}.auth.${region.value}.amazoncognito.com/oauth2/authorize` +                        `?identity_provider=${idp.value}` +                        `&redirect_uri=${process.env.VUE_APP_URL}/callback` +                        '&response_type=CODE' +                        `&client_id=${clientID.value}` +                        '&scope=email+openid+profile'
      window.location.href = location
    }

    const loginByEmail = () => {
      form.value.validate( async (valid) => { 
        if (!valid) {
            // validation error
            return
        }
        try{
          if(!await setCognito('Cognito')){
            return
          }

          // ユーザープール
          const userPool = new CognitoUserPool({
            UserPoolId: userPoolID.value,
            ClientId: clientID.value
          })
          // ユーザーの設定
          cognitoUser = new CognitoUser({
            Username: login.email,
            Pool: userPool
          })
          // パスワード設定
          const authData = new AuthenticationDetails({
            Username: login.email,
            Password: login.password
          })
          // 認証
          cognitoUser.authenticateUser(authData,{
            // 成功
            async onSuccess(result){
              move(result)
            },
            // 失敗
            onFailure(err){
              ElMessage.error('ログインに失敗しました')
            },
            newPasswordRequired(userAttributes, requiredAttributes){
                // 初回ログイン時はパスワード変更が必要
                changePasswordVisible.value = true
            },
          })
        }
        catch(error){
          ElMessage.error('ログインに失敗しました')
        }
      })
    }
    
    const confirmPassword = () => {
      changePasswordForm.value.validate( (valid) => { 
        if (!valid) {
            // validation error
            return
        }
        cognitoUser.completeNewPasswordChallenge(changePassword.password, {},{
          async onSuccess(result){
              move(result)
            },
            // 失敗
            onFailure(err){
              ElMessage.error('パスワード変更に失敗しました')
            },
        })
      })
    }

    const move = async (cognitoResult) => {
      sessionStorage.setItem('spf.id_token', cognitoResult.getIdToken().getJwtToken())
      sessionStorage.setItem('spf.refresh_token', cognitoResult.getRefreshToken().getToken())

      // 有効なユーザーかどうかチェック
      try{
        const res = await http.get('/v1/me')
        // 初期ページに遷移
        router.push('/maintenance/v1/company')
      }
      catch(reason){
        // ログイン失敗したらトークンは削除
        delete sessionStorage['spf.id_token']
        delete sessionStorage['spf.refresh_token']
        if (reason.response.status == 404) {
          // Cognitoには登録されているが、DBに登録されていない、もしくは削除済みの場合はエラーメッセージ表示
          ElMessage.error('ログインに失敗しました')
        } else {
          // エラーページに遷移
          router.push({ name: 'error', params: { message: '利用可能なユーザーとして登録されていません。' } })
        }
      }
    }

    return {
      form, login, rules, companyID, userPoolID, clientID, domain, idp, region, loginByEmail, loginByGoogle,
      changePasswordForm, changePasswordRules, changePasswordVisible, changePassword, confirmPassword,loginEmailEnable, loginGoogleEnable
    }
  }
})

